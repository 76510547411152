(function (global){
﻿'use strict';

var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
require('jquery.mmenu');

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeNavigation
    * @description Setup the navigation for the site.  Event handlers, etc
    **/
    var initializeNavigation = function () {

        console.log("topNavigation initialized");
        var $menu = $("#fwc-mobile-menu").mmenu({
            keyboardNavigation: {
                enable: true,
                enhance: true
            },
            navbar: {
                title: "Menu <a href='javascript:;' class='menu-close' aria-label='close menu'><span class='fas fa-times'></span></a>"
            },
            screenReader: {
                aria: true,
                //text: true,   // JASON TODO: Documenation is ambiguous, commenting this out for now - http://mmenu.frebsite.nl/documentation/core/screen-reader.html
                text: {
                    closeMenu: "Close menu",
                    closeSubmenu: "Close submenu",
                    openSubmenu: "Open submenu",
                    toggleSubmenu: "Toggle submenu"
                }
            },
            "extensions": [
                "position-right"
            ]
        });
        var API = $menu.data("mmenu");

        var $hamburger = $(".hamburger");
        $hamburger.on("click", function () {
            API.open();
        });

        var $close = $(".menu-close");
        $close.on("click", function () {
            API.close();
        });

        API.bind("open:finish", function () {
            setTimeout(function () {
                $hamburger.addClass("is-active");
            }, 100);
        });
        API.bind("close:finish", function () {
            setTimeout(function () {
                $hamburger.removeClass("is-active");
            }, 100);
        });
        // ADD ARIA LABELS TO MMENU ITEMS
        var $mobileMenuPanelTitles = $('.mm-navbar__title');
        $mobileMenuPanelTitles.each(function () {
            var $panelTitle = $(this);
            $panelTitle.attr("aria-label", "Go up one level in the menu to the " + $panelTitle.text() + " section");
        });

        var $mobileMenuCloseSubmenuLink = $('.mm-btn_prev.mm-navbar__btn');
        var $mobileMenuCloseSubmenuTitles = $('.mm-btn_prev.mm-navbar__btn .mm-sronly');
        $mobileMenuCloseSubmenuLink.each(function () {
            var $closePanelTitle = $(this.firstElementChild);
            //IN PROGRESS WORKING ON GETTING UNIQUE STRINGS FOR EACH CLOSE ARROW
            //var $panelTitle = $(this.getAttribute(URL));
            $closePanelTitle.text("Close this submenu and return to the previous section");
        });

        var width = $(window).width();

        $(document).ready(function() {
            if (width > 1349) {
                $("#desktop-nav-container").removeClass("container-fluid").addClass("container");
            }
        });

        $(window).on('resize', function () {
            setTimeout(function () {
                if (($(window).width() > 1349 && width < 1350) || ($(window).width() < 1350 && width > 1349)) {
                    $("#desktop-nav-container").toggleClass("container-fluid").toggleClass("container");
                }
                width = $(window).width();
            }, 500);
            
        });
    };

    // Return an object exposing the public items
    return {
        initialize: initializeNavigation
    };

}());

}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})