(function (global){
﻿'use strict';

var jquery = require('jquery');
global.$ = global.jQuery = jquery;      // To get the jQuery object in the global scope because Bootstrap 4 doesn't do modules properly
var bootstrap = require('bootstrap');

module.exports = (function () {

    // private methods


    // public methods

    /**
    * @ngdoc method
    * @name initializeAccordions
    * @description Setup an accordion.  Event handlers, etc
    **/
    var initializeAccordions = function () {

        $('.fwc-accordion-expand').on('click', function (e) {
            e.preventDefault();

            var accordionId = $(this).attr("data-id");

            $(accordionId + " .collapse").collapse('show');

            return false;
        });

        $('.fwc-accordion-collapse').on('click', function (e) {
            e.preventDefault();

            var accordionId = $(this).attr("data-id");

            $(accordionId + " .collapse").collapse('hide');

            return false;
        });

        console.log("accordion initialized");
    };

    // Return an object exposing the public items
    return {
        initialize: initializeAccordions
    };

}());


}).call(this,typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})